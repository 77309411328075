@use '@angular/material' as mat;
@include mat.core();

/* 500が基準のグリーンカラー */
$mat-custom-green: (
  50 : #e6f5f6,
  100 : #c1e5e9,
  200 : #98d4db,
  300 : #6ec3cd,
  400 : #4fb6c2,
  500 : #30a9b7,
  600 : #2ba2b0,
  700 : #2498a7,
  800 : #1e8f9f,
  900 : #137e90,
  A100 : #c3f6ff,
  A200 : #90eeff,
  A400 : #5de6ff,
  A700 : #44e2ff,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
  )
);

/* warn用オレンジカラー */
$mat-custom-orange: (
  50 : #eb7655,
  100 : #eb7655,
  200 : #eb7655,
  300 : #eb7655,
  400 : #eb7655,
  500 : #eb7655,
  600 : #eb7655,
  700 : #eb7655,
  800 : #eb7655,
  900 : #eb7655,
  contrast: (
      50 : #ffffff,
      100 : #ffffff,
      200 : #ffffff,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
  )
);

$my-primary: mat.define-palette($mat-custom-green);
$my-accent: mat.define-palette($mat-custom-green);
$my-warn: mat.define-palette($mat-custom-orange);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warn: $my-warn
 ),
 density: -1
));

@include mat.all-component-themes($my-theme);

:root {
  --mat-form-field-container-text-font: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic', arial, helvetica, sans-serif;
  --mdc-filled-text-field-input-text-color: #000;
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-tracking: 0;
  --mdc-dialog-supporting-text-font: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic', arial, helvetica, sans-serif;
  --mdc-dialog-subhead-tracking: 0;
  --mat-expansion-header-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mat-select-trigger-text-font: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic', arial, helvetica, sans-serif;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-weight: 400;
}
.mat-mdc-raised-button {
  --mdc-protected-button-container-height: 36px;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-font: Roboto, "Helvetica Neue", sans-serif;
  --mdc-protected-button-label-text-weight: 500;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
}
.mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 18px !important;
}
.mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
  left: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
}
.mdc-checkbox__ripple {
  --mdc-checkbox-selected-focus-state-layer-color: transparent;
  --mdc-checkbox-unselected-focus-state-layer-color: transparent;
}
.mdc-checkbox__background {
  --mdc-checkbox-unselected-focus-icon-color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-radio-button.mat-accent {
  --mat-radio-checked-ripple-color: transparent;
}
