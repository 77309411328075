/* You can add global styles to this file, and also import other style files */

/*テーマ設定*/
/* @import '~@angular/material/prebuilt-themes/indigo-pink.css'; */
@import '~@angular/cdk/overlay-prebuilt.css';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  /* padding-bottom: 90px; */ /* フッターを固定にする場合は必要 */
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic', arial,
    helvetica, sans-serif;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}
body:has(app-company-header) {
  overflow-y: scroll;
}
html.scroll-block,
html.scroll-block body {
  overflow: hidden !important;
  position: relative;
}
.spinner {
  margin-top: 20px;
  clear: both;
}
.spinner mat-spinner {
  margin: 0 auto;
}
.spinner.blue mat-spinner circle {
  stroke: var(--company-main-color, #25A99F);
}

h2,
h3 {
  margin-left: 23px;
}

a {
  cursor: pointer;
  color: #30A9B7;
}
.linky a{
  color: #3474ce;
}
.bold {
  font-weight: bold;
}
.mat-toolbar {
  font: 500 20px/32px 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', Meiryo, メイリオ, 'MS PGothic',
    arial, helvetica, sans-serif !important;
}

.mat-mdc-form-field {
  width: 100%;
}
.mat-mdc-form-field-infix {
  min-height: auto !important;
  padding-bottom: 6px !important;
  box-sizing: content-box !important;
}
.mat-mdc-form-field-infix input {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: bottom;  /* ios入力ずれ不具合対応 */
}

.required:after {
  content: '※';
  font-size: 12px;
  color: #ff5252;
  text-align: left;
}

.content {
  margin: 0 0.8em;
}

/* ボタン */
button {
  cursor: pointer;
}
.btn-event-none {
  pointer-events: none;
}
.button-container {
  width: 100%;
  text-align: center;
}
.button-container button {
  width: 280px;
  height: 50px;
  border-radius: 50px;
}
.button-container button.half {
  width: 35%;
  height: 50px;
  border-radius: 50px;
  margin: 0 18px;
}
.mat-mdc-dialog-container .button-container {
  text-align: center;
}
.mat-mdc-dialog-container .button-container button {
  margin: 0 6px 4px;
  max-width: 100%;
}
.mat-mdc-dialog-container .button-container button.half {
  width: 130px;
  margin: 0 10px 4px;
}
.mdc-button {
  font-size: 14px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: 500;
}
.mat-mdc-raised-button.mat-warn:not(:disabled) {
  color: #fff !important;
}

/* ラジオボタン　*/
mat-radio-button .mdc-label {
  background: #ffffff;
  border-radius: 6px;
  border: solid 1px #dddddd;
  height: 20px;
  line-height: 20px;
  text-align: center;
  padding: 10px 20px !important;
  font-size: 14px;
}
mat-radio-button.half-left .mdc-label {
  border-radius: 6px 0 0 6px;
  width: 80px;
}
mat-radio-button.half-right .mdc-label {
  border-radius: 0 6px 6px 0;
  width: 80px;
}
mat-radio-button .mdc-label,
mat-checkbox .mdc-label {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
/* ボタンタイプのラジオボタン */
mat-radio-group.radio-btn .mdc-radio {
  display: none;
}
mat-radio-group.radio-btn .mat-mdc-radio-checked .mdc-label {
  background-color: #30A9B7;
  color: #ffffff;
}

/* チェックボックス */
.mdc-form-field .mdc-label {
  padding-left: 0;
}
.mdc-form-field {
  white-space: normal !important;
}
.button-type .mdc-checkbox {
  display: none !important;
}
.button-type .mdc-form-field {
  background: #ffffff;
  border-radius: 6px;
  border: solid 1px #dddddd;
  height: 24px;
  text-align: center;
  padding: 8px 20px !important;
  font-size: 13px;
  margin: 0 5px 5px 0;
}
.button-type.mat-mdc-checkbox-checked .mdc-form-field {
  background-color: #30A9B7;
  color: #ffffff;
}
.consent-check .mdc-label {
  text-align: left;
}
/* 拡張パネル */
.mat-expansion-indicator::after {
  color: #30A9B7 !important;
}

/* セレクトボックス */
/* 矢印の位置調整 */
.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
  transform: translateY(-2px) !important;
}

/* テキスト */
.mat-mdc-form-field.no-placeholder .mat-mdc-form-field-infix {
  padding-top: 0 !important;
}
.mat-mdc-form-field .mat-mdc-form-field-infix textarea {
  font-size: 16px;
  line-height: 18px;
  box-sizing: content-box;
  padding: 4px 0;
  margin: -4px 0;
  white-space: break-spaces;
}
.mdc-text-field--filled {
  background-color: #fff !important;
  padding: 0 !important;
}
.mat-mdc-form-field-focus-overlay {
  background-color: #fff !important;
}
.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
  font-size: 75%;
}
.mat-mdc-form-field-hint {
  color: rgba(0, 0, 0, 0.54);
}
/* disabledテキストの下線を点線にする */
.mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before {
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
  border-bottom-color: transparent !important;
}
/* プロフィール期間入力 */
.mat-mdc-form-field.input-short {
  width: 50px;
}
.mat-mdc-form-field.input-short-s {
  width: 30px;
}
.mat-mdc-form-field.input-short .mat-mdc-form-field-infix,
.mat-mdc-form-field.input-short-s .mat-mdc-form-field-infix {
  line-height: 1;
}
.mat-mdc-form-field.input-short input,
.mat-mdc-form-field.input-short-s input {
  text-align: center;
  box-sizing: content-box;
  font-size: 14px;
}
.mat-mdc-form-field.input-short .mat-mdc-floating-label {
  width: 100%;
  text-align: center;
}
.mat-mdc-form-field.input-short .mat-mdc-floating-label.mdc-floating-label--float-above {
  padding-left: 8px;
}
.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
  top: 32px;
}
/* android対応 */
.android .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
  top: 30px;
}
.android .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label.mdc-floating-label--float-above {
  top: 22px;
}

/* ダイアログ */
.mdc-dialog .mdc-dialog__content {
  padding: 24px 15px 24px 15px !important;
}
.mat-mdc-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif !important;
  padding: 0 !important;
  margin-bottom: 20px !important;
}
.mdc-dialog__title::before {
  height: auto !important;
}
/* iOSでキーボード表示中にダイアログを表示した場合、ボタンが押せなくなる不具合の対応 */
.cdk-overlay-pane {
  transform: translateZ(0.01px);
}
.cdk-overlay-pane:has(.sp-menu-panel) {
  background-color: #fff;
}

/* 更新・送信ボタン上の送信中スピナー */
.sending-spinner-row {
  display: flex;
  justify-content: center;
  margin-bottom: -23px;
}
.sending-spinner-row .spinner-half {
  width: 35%;
  margin: 0px 18px;
}

/* ページトップへ戻るボタン */
.data-update-button {
  position: fixed;
  cursor: pointer;
  z-index: 10;
  right: 40%;
  bottom: 60px;
}
.data-update-button img {
  height: 36px;
  width: 36px;
}
/* 左560px + 右234px = 794px */
/* 画面幅793px以下で右メニューが非表示になるため位置を変更 */
@media (max-width: 793px) {
  .data-update-button {
    right: 4%;
  }
}

/* 下スクロールでデータ取得する時の最下ライン */
.bottom-line {
  height: 1px;
}
.scroll-message {
  text-align: center;
  color: #6e6e6e;
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 35px;
}
.spinner.scroll {
  margin-top: -30px;
}

/* PDF */
.pdfViewer.removePageBorders .page {
  margin-bottom: 0 !important;
}
.pdf-backdrop-class {
  background-color: rgba(0, 0, 0, 0.7);
}

/* LPモバイルメニュー背景 */
.menu-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}

/* chromeで画像がぼやける対応 */
_:lang(x)::-internal-media-controls-overlay-cast-button, img {
  image-rendering: -webkit-optimize-contrast;
}

/* メニューのスクロールバーを消す */
.mat-mdc-menu-content {
  overflow-x: hidden;
}

.mat-mdc-dialog-actions.mdc-dialog__actions {
  padding: 0 0 24px;
}

/* チェックボックス */
.mdc-checkbox__background {
  width: 16px !important;
  height: 16px !important;
}
.mat-expansion-panel-body {
  padding-left: 15px !important;
}
/* トースト */
.mat-mdc-snack-bar-container .mdc-snackbar__label {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 14px !important;
  line-height: 20px !important;
}
.mat-mdc-snack-bar-container {
  margin: 24px !important;
  max-width: 33vw;
  min-width: 344px;
}
.mat-mdc-simple-snack-bar .mdc-snackbar__label {
  padding: 14px 16px;
}
.mdc-snackbar__surface {
  padding-right: 0 !important;
}
.mat-mdc-snack-bar-handset .mat-mdc-snack-bar-container {
  margin: 8px !important;
  max-width: calc(100% - 16px);
  min-width: auto;
  width: 100%;
}

/* フォント */
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
.mat-mdc-select {
  -moz-osx-font-smoothing: auto !important;
  -webkit-font-smoothing: auto !important;
}
::placeholder {
  color: #cccccc !important;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label:not(.mdc-floating-label--float-above) {
  color: #cccccc !important;
}

/* quillエディタ */
.ql-snow .ql-editor {
  padding: 0;
  white-space: break-spaces;
  color: #434343;
  line-height: 2.25em;
}
@media (max-width: 600px) {
  .ql-snow .ql-editor {
    line-height: 2em;
  }
}
.ql-snow .ql-editor *:first-child {
  margin-top: 0 !important;
}
.ql-snow .ql-editor h3 {
  font-size: 28px !important;
  margin: 32px 0 16px 0;
  line-height: 1.42;
}
.ql-snow .ql-editor h4 {
  font-size: 20px !important;
  margin: 24px 0 12px 0;
  line-height: 1.42;
}
@media (max-width: 793px) {
  .ql-snow .ql-editor h3 {
    font-size: 22px !important;
    margin: 26px 0 14px 0;
  }
  .ql-snow .ql-editor h4 {
    font-size: 18px !important;
    margin: 20px 0 10px 0;
  }
}
.ql-snow .ql-editor p {
  font-size: 16px;
}
.ql-snow .ql-editor img {
  width: 100%;
  display: block;
  margin: 16px auto;
  user-select: none;
}
.ql-editor .ql-video {
  width: 100%;
  height: 450px;
  margin: 16px auto;
}
/*引用/強調*/
.ql-snow .ql-editor blockquote {
  font-size: 16px;
  background-color: #F8F8F8;
  border: 0 !important;
  padding: 16px 32px !important;
  margin: 16px auto !important;
}
@media (max-width: 793px) {
  .ql-snow .ql-editor blockquote {
    padding: 16px !important;
  }
}
/*箇条書き*/
.ql-editor ul {
  font-size: 16px;
  padding-left: 8px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.ql-editor ul li:not(.ql-direction-rtl):not(:last-child) {
  padding-bottom: 4px !important;
}
.ql-editor ul li:not(.ql-direction-rtl)::before {
  position: relative;
  right: 4px;
}
/*段落番号*/
.ql-editor ol {
  font-size: 16px;
  padding-left: 8px !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.ql-editor ol li:not(.ql-direction-rtl):not(:last-child) {
  padding-bottom: 4px !important;
}
@media (max-width: 793px) {
  .ql-editor ul {
    padding-left: 0 !important;
  }
  .ql-editor ol {
    padding-left: 0 !important;
  }
}
/*中央揃え*/
.ql-editor ul .ql-align-center {
  padding-left: 0 !important;
}
.ql-editor ol .ql-align-center {
  padding-left: 0 !important;
}
/*リンク*/
.ql-snow a {
  color: #434343 !important;
}

/* 目次 */
.ql-editor .toc-div {
  display: block;
  padding: 16px;
  width: 100%;
  text-align: left !important;
  margin: 24px auto;
  background-color: #F8F8F8;
  font-size: 16px;
}
.ql-editor .toc-title {
  font-weight: bold;
  padding-bottom: 16px;
}
.ql-editor .toc-title.bottom0 {
  padding-bottom: 0;
}
.ql-editor .toc-title.bottom0 span {
  display: none;
}
.ql-editor div.header-H3 {
  color: #666;
  cursor: pointer;
}
.ql-editor hr.header-H3 {
  margin: 8px 0;
}
.ql-editor div.header-H4 {
  margin-left: 16px;
  color: #666;
  cursor: pointer;
}
.ql-editor hr.header-H4 {
  margin: 6px 0 6px 16px;
}

/* OGP */
.ql-editor .ogp-loading {
  display: block;
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  height: 80px;
  margin: 14px 0;
}
.ql-editor .ogp-div {
  display: block;
  text-align: left !important;
  margin: 16px auto;
}
.ql-editor .ogp-div:has(a) {
  display: table;
  width: calc(100% - 2px);
  border: 1px solid #cccccc;
  border-radius: 4px;
  height: 80px;
}
.ql-editor .ogp-div a {
  display: table-cell;
  padding: 16px;
  vertical-align: middle;
  text-decoration: none !important;
  color: #000 !important;
  font-size: 12px;
}
.ql-editor .ogp-div a span {
  display: block;
  overflow: hidden;
  line-height: 1.5;
  opacity: 0.5;
}
.ql-editor .ogp-div a strong {
  display: block;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 700;
  opacity: 1;
  line-height: 1.42;
}
@media (max-width: 793px) {
  .ql-editor .ogp-div a strong {
    font-size: 14px;
  }
}
.ql-editor .ogp-div a span.desc {
  max-height: 3em;
  margin-bottom: 4px;
}
.ql-editor .ogp-div a.og-image {
  width: 30%;
  padding: 0;
  background-position: 50%;
  background-size: cover;
  border-left: #08131A08;
}
.ql-editor .ogp-div a.og-image img{
  vertical-align: middle;
  object-fit: cover;
  height: 100%;
  max-height: 180px;
  border-radius: 0 4px 4px 0;
  margin: 0;
}
@media (max-width: 793px) {
  .ql-editor .ogp-div a.og-image {
    width: 40%;
  }
  .ql-editor .ogp-div a.og-image img {
    max-height: 164px;
  }
}
/* エディタマウスオーバー */
@media only screen and (min-width: 931px) {
  .ql-editor div.header-H3:hover {
    color: #000;
  }
  .ql-editor div.header-H4:hover {
    color: #000;
  }
  .ql-editor .ogp-div:has(a):hover {
    border-color: #333333;
  }
  .ql-snow .ql-editor a:hover {
    text-decoration: none;
  }
}

